<template>
  <!-- Row Loop -->
  <b-row>
    <b-col lg="10" md="12">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="refsAnswerInfoRulesvlidate1" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Answer Details</h5>
            </b-col>
            <b-col md="6">
              <b-form-group label="title" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="title"
                    v-model="answer.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Answer Tittle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Answer Cover Image"
                label-for="CorseCoverImage"
              >
                <b-form-file
                  :id="index + 'answerimage'"
                  accept="image/*"
                  v-model="answer.imageCover"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Is Correct" :label-for="'isCorrect' + index">
                <b-form-checkbox
                  v-model="answers[index].isCorrect"
                  :id="'isCorrect' + index"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Hint" label-for="Hint">
                <validation-provider #default="{ errors }" name="Hint">
                  <b-form-textarea
                    id="Hint"
                    placeholder="Hint"
                    v-model="answer.hint"
                    :state="errors.length > 0 ? false : null"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
    </b-col>
    <!--  Buttons -->
    <b-col lg="1" md="12" class="mb-50">
      <b-row>
        <b-button
          v-if="!answer._id"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mt-0 mt-md-2 w-100"
          @click="createAnswer(answer, index)"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-0 mt-md-2 w-100"
          @click="updateanswerImage(answer)"
        >
          <feather-icon icon="EditIcon" class="mr-25" />
        </b-button>
      </b-row>
      <b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-danger"
          class="mt-0 mt-md-2 w-100"
          @click="removeItem(index)"
        >
          <feather-icon icon="XIcon" class="mr-25" />
        </b-button>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import answersApi from "@/apiServices/answersApi";
import imagesApi from "@/apiServices/imagesApi";

import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: ["answer", "answers", "index"],
  mixins: [heightTransition],

  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      Answer: {
        title: "",
        hint: "",
        isCorrect: false,
        imageCover: null,
      },

      nextTodoId: 2,
    };
  },
  mounted() {},
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    updateanswer(answer) {
      answersApi.updateanswer(answer).then((response) => {
        if (response.data) {
          // this.course = response.data;
          // console.log(response.data);
          this.$emit("updateanswerFromlist", this.id);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "answer updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    updateanswerImage(answer) {
      if (answer.imageCover instanceof File) {
        // console.log(answer.imageCover + "sdadasdasdasd");

        const imageFormData = new FormData();
        imageFormData.append("image", answer.imageCover);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          answer.imageCover = response;
          this.updateanswer(answer);
        });
      } else {
        delete answer.imageCover;
        this.updateanswer(answer);
      }
    },
    removeItem(index) {
      this.answers.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    createAnswers(AnswerFormData, index) {
      answersApi.createAnswer(AnswerFormData).then((response) => {
        if (response.data) {
          //   this.removeItem(index);
          console.log(response.data);
          this.answers[index] = response.data?.data;
          this.answer = response.data?.data;
          //   this.answers.push(this.answer);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Answer Created ",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    handleSubmit() {
      // Exit when the form isn't valid
    },
    createAnswerValidation(Answer, index) {
      return new Promise((resolve, reject) => {
        if (Answer.title != "") {
          this.createAnswers(Answer, index);
          resolve(true);
        } else {
          reject();
        }
      });
    },
    createAnswer(Answer, index) {
      // Prevent modal from closing
      if (Answer.imageCover instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("image", Answer.imageCover);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          Answer.imageCover = response;
          this.createAnswerValidation(Answer, index);
        });
      } else {
        delete this.Answer.imageCover;
        this.createAnswerValidation(Answer, index);
      }
      // Trigger submit handler
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
