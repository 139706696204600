<template>
  <b-card-code title="All answers " name="Answers" no-body>
    <b-card-body>
      <b-row>
        <answerRepiter :answers="answers" />
      </b-row>
    </b-card-body>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import answersApi from "@/apiServices/answersApi";
import answerRepiter from "@/views/answers pages/components/answer repiter.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    answerRepiter,
  },
  props: ["answers"],
  data() {
    return {
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 5,
        title: "",
      },
      options: ["video", "pdf", "powerpoint"],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "imageCover",
          label: "imageCover",
        },
        { key: "title", label: "Title" },
        { key: "isCorrect", label: "isCorrect" },
        { key: "hint", label: "hint" },
        "Action",
      ],
      /* eslint-disable global-require */
      // answers: [],
      /* eslint-disable global-require */
      status: [],
      //   codeKitchenSink,
    };
  },
  created() {},
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },

  methods: {
    deleteanswerFromlist(id) {
      console.log("hi from parent " + id + "******" + this.answers.length);
      this.answers = this.answers.filter(function (obj) {
        return obj._id != id;
      });
      console.log("hi ******" + this.answers.length);
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
