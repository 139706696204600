<template>
  <b-col class="mt-50">
    <!-- Row Loop -->
    <b-row>
      <b-col
        v-for="(Answer, index) in answers"
        :id="Answer.id"
        :key="Answer.id"
        ref="col"
        md="6"
      >
        <addAnserComponent :index="index" :answer="Answer" :answers="answers" />
        <hr />
      </b-col>
    </b-row>
    <b-row>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add New answer</span>
      </b-button>
    </b-row>
  </b-col>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import answersApi from "@/apiServices/answersApi";
import addAnserComponent from "@/views/answers pages/components/addAnswerComponent.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    addAnserComponent,
  },
  directives: {
    Ripple,
  },
  props: ["answers"],
  mixins: [heightTransition],
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      Answer: {
        title: "",
        hint: "",
        isCorrect: false,
        imageCover: null,
      },

      nextTodoId: 2,
    };
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    repeateAgain() {
      this.answers.push({
        title: "",
        hint: "",
        isCorrect: false,
        imageCover: null,
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
